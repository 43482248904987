<template>
    <div class="container err" :class='$mq'>
        <h2>Email не найден</h2>
        <p>К сожалению, нам не удалось найти ваш email адрес. Что делать? Зайти в настройки Вконтакте и привязать свой email.</p>
        <router-link to="/error_mail_instructions"><button>Как это исправить?</button></router-link>
     </div>
</template>


<script>
    export default {
        methods: {
            back (){
                this.$router.go(-1)
            }
        }
    }
</script>

<style scoped>
    .err {
        max-width: 40em;
        margin: 0 auto 8em auto;
        text-align: center;
    }
        .err.halftablet, .err.mobile {
            margin-bottom: 5em;
            font-size: 0.9em;
        }

    .err h2 {
        font-size: 5em;
        line-height: 1;
        margin: 1em 0 0.1em 0;
    }
        .err.halftablet h2 {
            font-size: 5em;
        }
        .err.mobile h2 {
            font-size: 2.5em;
            margin-bottom: 0.5em;
        }

    .err b {
        font-weight: 600;
    }

    button {
        padding: 1em 3em;
        margin-top: 2em;
        border: none;
        background-color: rgb(204, 97, 87);
        color: white;
        transition: all 0.3s cubic-bezier(.54,0,.4,1.51);
    }

    button:hover{
        transform: scale(1.1);
    }

    a {
        color: white;
    }
</style>